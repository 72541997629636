<template>
	<div id="adoptAgreement" class="el-content">
		<div class="tips">
			<p class="f12">
				注意合同生成采用了 wkhtmltopdf插件，中文字体会导致乱码，为了解决乱码问题，请将文件
				<a :href="font_url" target="_blank">simsun.ttc</a>
				下载后放到liunx服务器中的 /usr/share/fonts/文件夹下
			</p>
		</div>
		
		<a-form :label-col="{span:2}" :wrapper-col="{span:12}">
			<a-form-item label="是否启用合同">
				<a-space>
					<kd-switch 
						:active-value="1" 
						:inactive-value="0" 
						:value="form.land_contract_open" 
						@change="e=>form.land_contract_open = e">
					</kd-switch>
					<a-button type="link" @click="getTemplate">查看模板</a-button>			
				</a-space>
			</a-form-item>
			<a-form-item label="甲方名称">
				<div class="adopt-agreement-con" v-for="(item,index) in form.land_owner" :key="index">
					<a-space>
						<a-input style="width: 300px;" v-model:value="item.key" placeholder="标题 例如:甲方"></a-input>
						<a-input style="width: 300px;" v-model:value="item.value" placeholder="名称 例如:xxx有限公司"></a-input>
						<i class="ri-delete-bin-5-line ri-lg" @click="delOwaner(index)"></i>
					</a-space>
				</div>
				<div class="adopt-ageement-add" @click="addOwenr"><i class="ri-add-line ri-top"></i>添加</div>
			</a-form-item>
			<a-form-item label="乙方名称">
				<a-input v-model:value="form.land_party_b" placeholder="租赁人名称 例如:乙方"></a-input>
			</a-form-item>
			<a-form-item label="租赁协议">
				<tinymce-editor v-model="form.land_agreement"></tinymce-editor>
			</a-form-item>
			<a-form-item label="公章">
				<kd-img-select :src="form.land_common_seal" @success="e=>form.land_common_seal = e"></kd-img-select>
			</a-form-item>
			<a-form-item :wrapper-col="{offset:2}">
				<a-button type="primary" @click="submitSave">保存</a-button>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import global from '@/util/global.js'
import set from "@/api/set.js"
export default{
	setup(){
		const _d = reactive({
			form:{
				land_agreement:'',
				land_common_seal:'',
				land_party_b:'',
				land_contract_open:'0',
				land_owner:[
					{key:'',value:''}
				]
			},
			font_url:global.siteinfo.siteroot.split('//')[0]+'//'+global.siteinfo.siteroot.split('//')[1].split('/')[0]+'/simsun.ttc'
		})

		set.getFarmSet(Object.keys(_d.form)).then(res=>{
			_d.form = { ...res }
			_d.form.land_owner = res.land_owner ? JSON.parse(res.land_owner) :[]
		})

		const getTemplate = ()=>set.getLandContract()
		const addOwenr = ()=> _d.form.land_owner.push({key:'',value:''})
		const delOwaner = (index) => _d.form.land_owner.splice(index,1)

		function submitSave(){
			let param = JSON.parse(JSON.stringify(_d.form)) 
			param.land_owner = JSON.stringify(param.land_owner)
			set.setFarmSet(param)
		}

		return {
			...toRefs(_d),
			getTemplate,
			addOwenr,
			delOwaner,
			submitSave
		}
	},
}
</script>

<style lang="scss">
.adopt-agreement-con{
	display: flex;
	margin-bottom: 16px;
	align-items: center;
}
.adopt-ageement-add{
	width: 609px;
	border: 1px dashed #999;
	text-align: center;
	color: #666;
	cursor: pointer;
}
</style>
